import React, {Component} from 'react';
import {Container, Table} from 'react-bootstrap'

class Material2table extends Component {

    calculateRPM = (sfm, diam) => {
        return Math.round(sfm*3.82/diam);
    };

    calculateFeed = (sfm, diam, chipload) => {
        let rpm = Math.round(sfm*3.82/diam);
        return Math.round(rpm*4*chipload);
    };


    render() {
        return(
            <Container>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>RPM</th>
                        <th>Chipload</th>
                        <th>Feed</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0007</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0007)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0007</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0007)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0009</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0009)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0009</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0009)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0013</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0013)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0013</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0013)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0013</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0013)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0018</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0018)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0018</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0018)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.375)}</td>
                        <td>0.0022</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.375,  0.0022)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3750)}</td>
                        <td>0.0022</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3750,  0.0022)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0028</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0028)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0026</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0026)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0026</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0026)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0024</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0024)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0030</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0030)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0030</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0030)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0039</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0039)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0038</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0038)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 1.0)}</td>
                        <td>0.0045</td>
                        <td>{this.calculateFeed(this.props.sfm, 1.0,  0.0045)}</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default Material2table;