import React, {Component} from 'react';
import {Col, Row, Container, Card, Form } from 'react-bootstrap'

import './App.css';
import Material1table from "./material-tables/material1table";
import Diametertable from "./diametertable";
import Material2table from "./material-tables/material2table";
import Material3table from "./material-tables/material3table";
import Material4table from "./material-tables/material4table";
import Material5table from "./material-tables/material5table";

import color1 from './assets/colors/material1-color.png'
import color2 from './assets/colors/material2-color.png'
import color3 from './assets/colors/material3-color.png'
import color4 from './assets/colors/material4-color.png'
import color5 from './assets/colors/material5-color.png'

const MATERIAL_1 = 'Carbon Steel'
const MATERIAL_2 = 'Alloy Steel'
const MATERIAL_3 = 'Tool Steel'
const MATERIAL_4 = 'Alloys'
const MATERIAL_5 = 'Cast Irons'

const MATERIAL_1_MIN_SFM = 300
const MATERIAL_1_MAX_SFM = 500

const MATERIAL_2_MIN_SFM = 250
const MATERIAL_2_MAX_SFM = 350

const MATERIAL_3_MIN_SFM = 230
const MATERIAL_3_MAX_SFM = 300

const MATERIAL_4_MIN_SFM = 80
const MATERIAL_4_MAX_SFM = 160

const MATERIAL_5_MIN_SFM = 450
const MATERIAL_5_MAX_SFM = 700


const MATERIAL_1_RANGE = `${MATERIAL_1_MIN_SFM}-${MATERIAL_1_MAX_SFM}`
const MATERIAL_2_RANGE = `${MATERIAL_2_MIN_SFM}-${MATERIAL_2_MAX_SFM}`
const MATERIAL_3_RANGE = `${MATERIAL_3_MIN_SFM}-${MATERIAL_3_MAX_SFM}`
const MATERIAL_4_RANGE = `${MATERIAL_4_MIN_SFM}-${MATERIAL_4_MAX_SFM}`
const MATERIAL_5_RANGE = `${MATERIAL_5_MIN_SFM}-${MATERIAL_5_MAX_SFM}`

class App extends Component
{
  constructor(props)
  {
    super(props);
    console.log(MATERIAL_1_RANGE)
    this.state = { materialSelectedOption: MATERIAL_1, sfmRange: "300-500", sfm: 300, ae: '0.1 X Cutting Diameter', ap: '1.5 X Cutting Diameter'  }

  }

  tableRender = () => {
    if(this.state.materialSelectedOption === MATERIAL_1) {
      return (
          <Material1table sfm={this.state.sfm}/>
      )
    } else if(this.state.materialSelectedOption === MATERIAL_2) {
      return (
          <Material2table sfm={this.state.sfm}/>
      )
    } else if(this.state.materialSelectedOption === MATERIAL_3) {
      return(
        <Material3table sfm={this.state.sfm}/>
      )
    } else if(this.state.materialSelectedOption === MATERIAL_4) {
      return (
          <Material4table sfm={this.state.sfm}/>
      )

    } else if(this.state.materialSelectedOption === MATERIAL_5) {
        return (
            <Material5table sfm={this.state.sfm}/>
        )
    }
  }

  handleChange = (event) => {
    this.setState({sfm: event.target.value})
    //event.preventDefault()
  }

  handleOptionChange = (changeEvent) => {
    console.log(changeEvent.target.id)
    if (changeEvent.target.id === 'material1'){
      this.setState({
        materialSelectedOption: MATERIAL_1,
        sfmRange: MATERIAL_1_RANGE,
        ae: '0.1 X Cutting Diameter',
        ap: '1.5 X Cutting Diameter',
      });
    } else if (changeEvent.target.id === 'material2'){
      this.setState({
        materialSelectedOption: MATERIAL_2,
        sfmRange: MATERIAL_2_RANGE,
        ae: '0.1 X Cutting Diameter',
        ap: '1.5 X Cutting Diameter',
      });
    } else if (changeEvent.target.id === 'material3'){
      this.setState({
        materialSelectedOption: MATERIAL_3,
        sfmRange: MATERIAL_3_RANGE,
        ae: '0.05 X Cutting Diameter',
        ap: '1.5 X Cutting Diameter',
      });
    } else if (changeEvent.target.id === 'material4'){
      this.setState({
        materialSelectedOption: MATERIAL_4,
        sfmRange: MATERIAL_4_RANGE,
        ae: '0.05 X Cutting Diameter',
        ap: '1.5 X Cutting Diameter',
      });
    } else if (changeEvent.target.id === 'material5'){
      this.setState({
        materialSelectedOption: MATERIAL_5,
        sfmRange: MATERIAL_5_RANGE,
        ae: '0.1 X Cutting Diameter',
        ap: '1.5 X Cutting Diameter',
      });
    }

  }

  render() {
    return (
        <Container>
          <Row className="justify-content-md-center mt-5 mb-5">
            <Col xs lg="7" className="text-center">
              <h2>High Performance Drill Technology</h2>
              <h3>Endmills 5 Flute</h3>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
              <Col md lg="2">
                <h1>Step 1</h1>
                <p> Choose Material</p>
              </Col>
              <Col lg lg="2">
                <Card>
                  <Card.Img variant="top" src={color1}/>
                  <Card.Body>
                    <Card.Title>Carbon Steel/Stainless Steel</Card.Title>
                    <Card.Text>
                      20-40 HRC
                    </Card.Text>
                    <Form.Check
                        type="radio"
                        label="Calculate"
                        checked={this.state.materialSelectedOption === MATERIAL_1}
                        onChange={this.handleOptionChange}
                        name="formHorizontalRadios"
                        id="material1"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg lg="2">
                <Card>
                  <Card.Img variant="top" src={color2}/>
                  <Card.Body>
                    <Card.Title>Pre-Hard Steel/Alloy Steel</Card.Title>
                    <Card.Text>
                      1,400 N/mm² / 30-45 HRD
                    </Card.Text>
                    <Form.Check
                        type="radio"
                        label="Calculate"
                        checked={this.state.materialSelectedOption === MATERIAL_2}
                        onChange={this.handleOptionChange}
                        name="formHorizontalRadios"
                        id="material2"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg lg="2">
                <Card>
                  <Card.Img variant="top" src={color3}/>
                  <Card.Body>
                    <Card.Title>Hardened Steel / Tool Steel</Card.Title>
                    <Card.Text>
                      2,000 N/mm² / 45-55 HRC
                    </Card.Text>
                    <Form.Check
                        type="radio"
                        label="Calculate"
                        name="formHorizontalRadios"
                        checked={this.state.materialSelectedOption === MATERIAL_3}
                        onChange={this.handleOptionChange}
                        id="material3"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg lg="2">
                <Card>
                  <Card.Img variant="top" src={color4}/>
                  <Card.Body>
                    <Card.Title>High Temp. Alloys /Nickel Cobalt Alloys </Card.Title>
                    <Card.Text>
                      850-2,180 N/mm² / 25 - 60 HRC
                    </Card.Text>
                    <Form.Check
                        type="radio"
                        label="Calculate"
                        checked={this.state.materialSelectedOption === MATERIAL_4}
                        onChange={this.handleOptionChange}
                        name="formHorizontalRadios"
                        id="material4"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg lg="2">
                <Card>
                  <Card.Img variant="top" src={color5}/>
                  <Card.Body>
                    <Card.Title>Cast Irons / Ductile Cast Iron  </Card.Title>
                    <Card.Text>
                      100-400 N/mm² / 350-800 N/mm²
                    </Card.Text>
                    <Form.Check
                        type="radio"
                        label="Calculate"
                        name="formHorizontalRadios"
                        checked={this.state.materialSelectedOption === MATERIAL_5}
                        onChange={this.handleOptionChange}
                        id="material5"
                    />
                  </Card.Body>
                </Card>
              </Col>
          </Row>
          <Row className="tb-row-spacing">
            <Col md lg="2">
              <h3>Specs</h3>
              <p></p>
            </Col>
            <Col md lg="10">
              <Container>
                <p>Side Milling Suggested SFM Range <b>{this.state.sfmRange}</b></p>
                <p>Radial Cutting Depth (ae) <b>{this.state.ae}</b></p>
                <p>Axial Cutting Depth (ap) <b>{this.state.ap}</b></p>
              </Container>
            </Col>

          </Row>
          <Row className="tb-row-spacing">
            <Col md lg="2">
              <h1>Step 2</h1>
              <p>Enter SFM from {this.state.sfmRange}</p>
            </Col>
            <Col md lg="10">
              <Container>
                <Form>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Col sm="10">
                      <Form.Control type="number" defaultValue={this.state.sfm} placeholder={this.state.sfmRange} onChange={this.handleChange}/>
                    </Col>
                  </Form.Group>
                </Form>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md lg="2">
              <h1>Step 3</h1>
              <p>Compare RPMs</p>
              <h3>Units</h3>
              <p>Chipload - inch/z</p>
              <p>Feed - inch/m</p>
            </Col>
            <Col xs sm="5" lg="4" className="no-right-padding">
              <Diametertable/>
            </Col>
            <Col xs sm="5" lg="4" className="no-right-padding no-left-padding">
              {this.tableRender()}
            </Col>
          </Row>
        </Container>
    );
  }
}

export default App;
