import React, {Component} from 'react';
import {Container, Table} from 'react-bootstrap'

class Material3table extends Component {

    calculateRPM = (sfm, diam) => {
        return Math.round(sfm*3.82/diam);
    };

    calculateFeed = (sfm, diam, chipload) => {
        let rpm = (sfm*3.82/diam);
        return Math.round(rpm*4*chipload);
    };


    render() {
        return(
            <Container>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>RPM</th>
                        <th>Chipload</th>
                        <th>Feed</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0006</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0006)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0006</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0006)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0008</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0008)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0008</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0008)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0012</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0012)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0012</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0012)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0012</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0012)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0016</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0016)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0016</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0016)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3750)}</td>
                        <td>0.0020</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3750,  0.0020)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3750)}</td>
                        <td>0.0020</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3750,  0.0020)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0025</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0025)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0024</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0024)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0024</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0024)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0021</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0021)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0027</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0027)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0027</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0027)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0035</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0035)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0034</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0034)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 1.0)}</td>
                        <td>0.0040</td>
                        <td>{this.calculateFeed(this.props.sfm, 1.0,  0.0040)}</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default Material3table;