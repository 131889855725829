import React, {Component} from 'react';
import {Table, Container} from 'react-bootstrap'

class Diametertable extends Component {

    render() {
        return(
            <Container className="no-right-padding">
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>D</th>
                        <th>LOC</th>
                        <th>D</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1/8</td>
                        <td>1/4</td>
                        <td>0.1250</td>
                    </tr>
                    <tr>
                        <td>1/8</td>
                        <td>1/2</td>
                        <td>0.1250</td>
                    </tr>
                    <tr>
                        <td>3/16</td>
                        <td>3/8</td>
                        <td>0.1875</td>
                    </tr>
                    <tr>
                        <td>3/16</td>
                        <td>5/8</td>
                        <td>0.1875</td>
                    </tr>

                    <tr>
                        <td>1/4</td>
                        <td>1/2</td>
                        <td>0.2500</td>
                    </tr>
                    <tr>
                        <td>1/4</td>
                        <td>3/4</td>
                        <td>0.2500</td>
                    </tr>
                    <tr>
                        <td>1/4</td>
                        <td>5/8</td>
                        <td>0.2500</td>
                    </tr>

                    <tr>
                        <td>5/16</td>
                        <td>5/8</td>
                        <td>0.3125</td>
                    </tr>
                    <tr>
                        <td>5/16</td>
                        <td>3/4</td>
                        <td>0.3125</td>
                    </tr>

                    <tr>
                        <td>3/8</td>
                        <td>5/8</td>
                        <td>0.3750</td>
                    </tr>
                    <tr>
                        <td>3/8</td>
                        <td>7/8</td>
                        <td>0.3750</td>
                    </tr>

                    <tr>
                        <td>1/2</td>
                        <td>5/8</td>
                        <td>0.5000</td>
                    </tr>
                    <tr>
                        <td>1/2</td>
                        <td>1</td>
                        <td>0.5000</td>
                    </tr>
                    <tr>
                        <td>1/2</td>
                        <td>1-1/4</td>
                        <td>0.5000</td>
                    </tr>
                    <tr>
                        <td>1/2</td>
                        <td>1-1/2</td>
                        <td>0.5000</td>
                    </tr>

                    <tr>
                        <td>5/8</td>
                        <td>3/4</td>
                        <td>0.6250</td>
                    </tr>
                    <tr>
                        <td>5/8</td>
                        <td>1-1/4</td>
                        <td>0.6250</td>
                    </tr>

                    <tr>
                        <td>3/4</td>
                        <td>1</td>
                        <td>0.7500</td>
                    </tr>
                    <tr>
                        <td>3/4</td>
                        <td>1-1/2</td>
                        <td>0.7500</td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>1-1/2</td>
                        <td>1.0000</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default Diametertable;