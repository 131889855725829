import React, {Component} from 'react';
import {Container, Table} from 'react-bootstrap'

class Material4table extends Component {

    calculateRPM = (sfm, diam) => {
        return Math.round(sfm*3.82/diam);
    };

    calculateFeed = (sfm, diam, chipload) => {
        let rpm = (sfm*3.82/diam);
        return Math.round(rpm*4*chipload);
    };


    render() {
        return(
            <Container>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>RPM</th>
                        <th>Chipload</th>
                        <th>Feed</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0005</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0005)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1250)}</td>
                        <td>0.0005</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1250,  0.0005)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0007</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0007)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.1875)}</td>
                        <td>0.0007</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.1875,  0.0007)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0011</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0011)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0011</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0011)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.2500)}</td>
                        <td>0.0011</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.2500,  0.0011)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0015</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0015)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3125)}</td>
                        <td>0.0015</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3125,  0.0015)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3750)}</td>
                        <td>0.0018</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3750,  0.0018)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.3750)}</td>
                        <td>0.0018</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.3750,  0.0018)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0023</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0023)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0021</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0021)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0021</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0021)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.5000)}</td>
                        <td>0.0019</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.5000,  0.0019)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0024</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0024)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.6250)}</td>
                        <td>0.0024</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.6250,  0.0024)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0032</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0032)}</td>
                    </tr>
                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 0.7500)}</td>
                        <td>0.0030</td>
                        <td>{this.calculateFeed(this.props.sfm, 0.7500,  0.0030)}</td>
                    </tr>

                    <tr>
                        <td>{this.calculateRPM(this.props.sfm, 1.0)}</td>
                        <td>0.0036</td>
                        <td>{this.calculateFeed(this.props.sfm, 1.0,  0.0036)}</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default Material4table;